<template>
  <div>
    <div class="outer" v-if="!registrationFlag">
      <div class="overflow-hidden" v-if="packageDetails">
        <div>
          <v-container v-if="item">
            <br /><br /><br /><br /><br />
            <div style="margin-bottom: 15px">
              <h2 class="exploretitle">{{ packageDetails.package_title }}</h2>
              <span>
                <v-icon color="yellow">mdi-map-marker</v-icon>
                {{ packageDetails.city }}, {{ packageDetails.country }}
              </span>
            </div>
            <tb-package-images
              :packageImages="packageDetails.assets"
            ></tb-package-images>
            <div
              v-if="packageDetails.assets.length >= 5"
              style="
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;
                margin-bottom: 10px;
              "
            >
              <v-btn
                color="black darken-3"
                small
                text
                @click="isShowMoreImages = !isShowMoreImages"
              >
                <v-icon class="margin-right: 5px;">mdi-apps</v-icon> Show all
                photos</v-btn
              >

              <!-- Show More -->
              <v-dialog
                transition="dialog-top-transition"
                max-width="700"
                v-model="isShowMoreImages"
              >
                <v-card>
                  <v-toolbar flat>
                    <v-btn icon @click="isShowMoreImages = false">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-carousel
                      cycle
                      show-arrows-on-hover
                      hide-delimiter-background
                      delimiter-icon="mdi-minus"
                    >
                      <v-carousel-item
                        v-for="(image, index) in packageDetails.assets"
                        v-bind:key="index"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      >
                        <v-img
                          :src="image.asset_url"
                          :lazy-src="image.asset_url"
                          height="100%"
                        />
                      </v-carousel-item>
                    </v-carousel>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
            <br /><br />
            <v-row>
              <v-col cols="12" xs="12" sm="8" md="8" lg="8">
                <div>
                  <h4>
                    Hosted by {{ packageDetails.posted_by.split('@')[0] }}
                  </h4>

                  <v-card
                    elevation="0"
                    style="
                      margin-top: 5px;
                      background: transparent;
                      padding-left: 0px;
                    "
                  >
                    <h2 style="font-weight: 400; opacity: 0.8">Availability</h2>
                    <p>{{ getAvailabilityDates(dates) }}</p>
                  </v-card>
                </div>
                <br />
                <v-divider></v-divider>
                <br />
                <div>
                  <p
                    v-html="
                      packageDetails.package_description.slice(0, 600) + '...'
                    "
                  ></p>

                  <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    hide-overlay
                    fullscreen
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <button
                        v-bind="attrs"
                        v-on="on"
                        class="btn btn-success"
                        @click="readMore1 = !readMore1"
                        style="margin-top: 15px"
                      >
                        <h3>Show More</h3>
                      </button>
                    </template>
                    <v-toolbar dark color="yellow">
                      <v-btn icon color="black" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title style="color: #1a1a1a"
                        >Package Description</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card>
                      <!--v-toolbar flat>
                        <v-btn icon @click="dialog = false">
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-title>
                        <span class="text-h5">Package Description</span>
                      </v-card-title -->
                      <v-card-text>
                        <div
                          style="padding: 16px"
                          v-html="packageDetails.package_description"
                        ></div>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
                <v-divider
                  style="margin-top: 15px; margin-bottom: 15px"
                ></v-divider>
                <v-row>
                  <v-col cols="12">
                    <div v-if="accomodationImages !== undefined">
                      <h4 class="space10">Where You'll Sleep</h4>
                      <v-carousel
                        cycle
                        height="250"
                        class="imgstyle2"
                        show-arrows-on-hover
                        hide-delimiter-background
                      >
                        <v-carousel-item
                          v-for="(image, index) in packageDetails.accommodation"
                          v-bind:key="index"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        >
                          <v-img
                            :src="image.asset_url"
                            :lazy-src="image.asset_url"
                            eager
                            aspect-ratio="1:4"
                            height="100%"
                          />
                        </v-carousel-item>
                      </v-carousel>
                      <br />
                    </div>

                    <div
                      class="exploreclr"
                      v-if="packageDetails.bed_details[0].single_bed"
                    >
                      <v-row> <h5 class="bedstyl">Single bed</h5> </v-row>
                    </div>

                    <div
                      class="exploreclr"
                      v-if="packageDetails.bed_details[0].double_bed"
                    >
                      <v-row> <h5 class="bedstyl">Double bed</h5> </v-row>
                    </div>

                    <div
                      class="exploreclr"
                      v-if="packageDetails.bed_details[0].triple_bed"
                    >
                      <v-row> <h5 class="bedstyl">Triple bed</h5></v-row>
                    </div>

                    <div
                      class="exploreclr"
                      v-if="packageDetails.bed_details[0].dormitory"
                    >
                      <v-row> <h5 class="bedstyl">Dormitory</h5></v-row>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div v-if="carImages !== undefined">
                      <h4 class="space10">Car Details</h4>
                      <v-carousel
                        cycle
                        height="250"
                        show-arrows-on-hover
                        hide-delimiter-background
                      >
                        <v-carousel-item
                          v-for="(
                            image, imageindex
                          ) in packageDetails.car_details"
                          v-bind:key="imageindex"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        >
                          <v-img
                            :src="image.asset_url"
                            :lazy-src="image.asset_url"
                            eager
                            aspect-ratio="1:4"
                            height="100%"
                          />
                        </v-carousel-item>
                      </v-carousel>
                      <br />
                      <v-divider></v-divider>
                    </div>
                  </v-col>
                </v-row>
                <br />

                <br />
                <div v-if="packageDetails.car_details[0].car_capacity != 0">
                  <h4 class="bedstyl exploreclr">
                    Car capacity:{{
                      packageDetails.car_details[0].car_capacity
                    }}
                  </h4>
                  <h4 class="bedstyl exploreclr">
                    Car type:{{ packageDetails.car_details[0].car_type }}
                  </h4>
                  <h4 class="bedstyl exploreclr">
                    Sightseeing:
                    {{ packageDetails.sight_seen_details[0].site_seen_name }}
                  </h4>

                  <div class="row">
                    <v-col
                      cols="3"
                      v-if="packageDetails.sight_seen_details[0].service_for_4h"
                    >
                      <v-chip class="ma-2" color="indigo" text-color="white">
                        <v-avatar left>
                          <v-icon>commute</v-icon>
                        </v-avatar>
                        service for 4h
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="3"
                      v-if="packageDetails.sight_seen_details[0].service_for_6h"
                    >
                      <v-chip class="ma-2" color="indigo" text-color="white">
                        <v-avatar left>
                          <v-icon>commute</v-icon>
                        </v-avatar>
                        service for 6h
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="3"
                      v-if="packageDetails.sight_seen_details[0].service_for_8h"
                    >
                      <v-chip class="ma-2" color="indigo" text-color="white">
                        <v-avatar left>
                          <v-icon>commute</v-icon>
                        </v-avatar>
                        service for 8h
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="3"
                      v-if="packageDetails.sight_seen_details[0].skip"
                    >
                      <v-chip class="ma-2" color="indigo" text-color="white">
                        <v-avatar left>
                          <v-icon>commute</v-icon>
                        </v-avatar>
                        No services
                      </v-chip>
                    </v-col>
                  </div>
                  <br />
                </div>
                <v-divider></v-divider>

                <br />
                <h3 class="space10 exploretitle">What This Place Offers</h3>
                <br />

                <div>
                  <v-row style="margin: 0px">
                    <v-col cols="6">
                      <div v-if="packageDetails.amenities.includes('Kitchen')">
                        <v-row
                          ><img
                            class="imgicon"
                            src="../assets/img/kitchen.svg"
                          />
                          kitchen</v-row
                        >
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row>
                          <img
                            class="imgicon"
                            src="../assets/img/kitchen.svg"
                          />
                          Kitchen
                        </v-row>
                        <br />
                        <br />
                      </div>
                      <div
                        v-if="
                          packageDetails.facilities.includes('Free Parking')
                        "
                      >
                        <v-row
                          ><img
                            class="imgicon"
                            src="../assets/img/parking.svg"
                          />Free parking on premises</v-row
                        >
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row
                          ><img
                            class="imgicon"
                            src="../assets/img/parking.svg"
                          />Free parking on premises</v-row
                        >
                        <br /><br />
                      </div>
                      <div v-if="packageDetails.amenities.includes('Balcony')">
                        <v-row>
                          <img
                            class="imgicon"
                            src="../assets/img/balcony.svg"
                          />Patio or balcony</v-row
                        >
                        <br />
                        <br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row>
                          <img
                            class="imgicon"
                            src="../assets/img/balcony.svg"
                          />Patio or balcony</v-row
                        >
                        <br />
                        <br />
                      </div>
                      <div
                        v-if="
                          packageDetails.amenities.includes('Air-condition')
                        "
                      >
                        <v-row>
                          <img class="imgicon" src="../assets/img/ac.svg" />Air
                          conditioning</v-row
                        >
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row>
                          <img class="imgicon" src="../assets/img/ac.svg" />Air
                          conditioning</v-row
                        >
                        <br /><br />
                      </div>
                      <div v-if="packageDetails.amenities.includes('Wi-fi')">
                        <v-row
                          ><img class="imgicon" src="../assets/img/wifi.svg" />
                          Wifi</v-row
                        >
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row
                          ><img class="imgicon" src="../assets/img/wifi.svg" />
                          Wifi</v-row
                        >
                        <br /><br />
                      </div>
                      <!-- wifi -->
                    </v-col>
                    <v-col cols="6">
                      <!-- fire -->
                      <div
                        v-if="
                          packageDetails.amenities.includes('Indoor-Fireplace')
                        "
                      >
                        <v-row
                          ><img class="imgicon" src="../assets/img/fire.svg" />
                          Fireplace</v-row
                        >
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row
                          ><img class="imgicon" src="../assets/img/fire.svg" />
                          Fireplace</v-row
                        >
                        <br /><br />
                      </div>
                      <div
                        v-if="packageDetails.amenities.includes('Water-Font')"
                      >
                        <v-row
                          ><img
                            class="imgicon"
                            src="../assets/img/water1.svg"
                          />
                          Water front</v-row
                        >
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row
                          ><img
                            class="imgicon"
                            src="../assets/img/water1.svg"
                          />
                          Water front</v-row
                        >
                        <br /><br />
                      </div>

                      <div v-if="packageDetails.facilities.includes('Pool')">
                        <v-row
                          ><img class="imgicon" src="../assets/img/pool.svg" />
                          Pool</v-row
                        >
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row
                          ><img class="imgicon" src="../assets/img/pool.svg" />
                          Pool</v-row
                        >
                        <br /><br />
                      </div>
                      <div
                        v-if="packageDetails.amenities.includes('Beach-Font')"
                      >
                        <v-row>
                          <img class="imgicon" src="../assets/img/beach.svg" />
                          Beach font
                        </v-row>
                        <br /><br />
                      </div>
                      <div style="text-decoration: line-through" v-else>
                        <v-row>
                          <img class="imgicon" src="../assets/img/beach.svg" />
                          Beach font
                        </v-row>
                        <br /><br />
                      </div>
                      <div v-if="packageDetails.amenities.includes('Heating')">
                        <v-row>
                          <img
                            class="imgicon"
                            src="../assets/img/heating.svg"
                          />
                          Heating
                        </v-row>
                      </div>

                      <div style="text-decoration: line-through" v-else>
                        <v-row
                          ><img
                            class="imgicon"
                            src="../assets/img/heating.svg"
                          />
                          Heating</v-row
                        >
                        <br /><br />
                      </div>
                    </v-col>
                  </v-row>
                  <!-- What this place offers -->
                  <v-dialog v-model="offersDialog" scrollable max-width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <h3 v-bind="attrs" v-on="on">Show More</h3>
                    </template>
                    <v-card>
                      <v-app-bar absolute flat color="white">
                        <v-btn icon @click="offersDialog = false">
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </v-app-bar>
                      <br /><br />
                      <v-card-title>
                        <span class="text-h5">Amenities</span>
                      </v-card-title>
                      <v-card-text style="height: 500px">
                        <h3>
                          <span class="text-h6">Bathroom</span>
                        </h3>
                        <div style="padding-bottom: 0px">
                          <v-container>
                            <v-col>
                              <div
                                v-if="
                                  packageDetails.amenities.includes(
                                    'Washing-Machine'
                                  )
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/washing.svg"
                                  />Washing Machine</v-row
                                >
                                <br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/washing.svg"
                                  />Washing Machine</v-row
                                >
                                <br /><br />
                              </div>
                              <div
                                v-if="
                                  packageDetails.amenities.includes(
                                    'Hair Dryer'
                                  )
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/air.svg"
                                  />Hair Dryer</v-row
                                ><br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/air.svg"
                                  />Hair Dryer</v-row
                                ><br /><br />
                              </div>
                              <div
                                v-if="
                                  packageDetails.amenities.includes(
                                    'Private Bathroom'
                                  )
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/bathroom.svg"
                                  />Private Bathroom</v-row
                                ><br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/bathroom.svg"
                                  />Private Bathroom</v-row
                                ><br /><br />
                              </div>
                              <div
                                v-if="
                                  packageDetails.facilities.includes('Hot_Tub')
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/hot.svg"
                                  />Hot Tub</v-row
                                >
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/hot.svg"
                                  />Hot Tub</v-row
                                >
                              </div>
                            </v-col>
                          </v-container>
                        </div>
                        <h3>
                          <span class="text-h6">Bedroom and laundry</span>
                        </h3>
                        <div style="padding-bottom: 0px">
                          <v-container>
                            <v-col>
                              <div
                                v-if="
                                  packageDetails.amenities.includes('Dryer')
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/dry.svg"
                                  />Dryer</v-row
                                >
                                <br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/dry.svg"
                                  />Dryer</v-row
                                >
                                <br /><br />
                              </div>
                              <div
                                v-if="packageDetails.amenities.includes('Iron')"
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/iron.svg"
                                  />Iron
                                </v-row>
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/iron.svg"
                                  />Iron
                                </v-row>
                              </div>
                            </v-col>
                          </v-container>
                        </div>
                        <h3>
                          <span class="text-h5">Facilities</span>
                        </h3>
                        <div style="padding-bottom: 0px">
                          <v-container>
                            <v-col>
                              <div
                                v-if="packageDetails.facilities.includes('Gym')"
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/gym.svg"
                                  />
                                  <h3>Gym</h3></v-row
                                >

                                <br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/gym.svg"
                                  />
                                  <h3>Gym</h3></v-row
                                >
                                <br /><br />
                              </div>
                              <div
                                v-if="packageDetails.amenities.includes('Tv')"
                              >
                                <v-row>
                                  <img
                                    class="imgicon"
                                    src="../assets/img/tv.svg"
                                  />
                                  <h3>TV</h3></v-row
                                >
                                <br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row>
                                  <img
                                    class="imgicon"
                                    src="../assets/img/tv.svg"
                                  />
                                  <h3>TV</h3></v-row
                                >
                                <br /><br />
                              </div>
                              <div
                                v-if="
                                  packageDetails.amenities.includes(
                                    'High-chair'
                                  )
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/chair.svg"
                                  />High Chair</v-row
                                >
                                <br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/chair.svg"
                                  />High Chair</v-row
                                >
                                <br /><br />
                              </div>
                              <div
                                v-if="
                                  packageDetails.amenities.includes(
                                    'Water-Font'
                                  )
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/water.svg"
                                  />Water Font</v-row
                                >
                                <br /><br />
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/water.svg"
                                  />Water Font</v-row
                                >
                                <br /><br />
                              </div>
                              <div
                                v-if="
                                  packageDetails.amenities.includes(
                                    'Beach-Font'
                                  )
                                "
                              >
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/beach.svg"
                                  />Beach Font</v-row
                                >
                              </div>
                              <div style="text-decoration: line-through" v-else>
                                <v-row
                                  ><img
                                    class="imgicon"
                                    src="../assets/img/beach.svg"
                                  />Beach Font</v-row
                                >
                              </div>
                            </v-col>
                          </v-container>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
                <br />
              </v-col>
              <!-- Check availability -->
              <v-col
                cols="12"
                xs="12"
                sm="4"
                md="4"
                lg="4"
                style="padding-top: 20px"
              >
                <v-card
                  elevation="5"
                  class="mx-auto resize66"
                  height="auto"
                  max-width="350"
                >
                  <v-card-title style="padding: 0px">
                    <p>US${{ packageDetails.price }} / night</p>
                  </v-card-title>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-menu
                        ref="startMenu"
                        :close-on-content-click="false"
                        :return-value.sync="trip.start"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-text-field
                              v-model="trip.start"
                              label="Check in"
                              append-icon="mdi-calendar"
                              outlined
                              readonly
                              color="yellow darken-1"
                              style="margin-right: 3px"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="dateStart"
                          :min="new Date().toISOString().substr(0, 10)"
                          no-title
                          scrollable
                          color="yellow darken-1"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="yellow darken-1"
                            @click="$refs.startMenu.isActive = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="yellow darken-1"
                            @click="
                              $refs.startMenu.save(dateStart);
                              convertCheckInDate();
                              dateEnd = dateStart;
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-menu
                        ref="endMenu"
                        :close-on-content-click="false"
                        :return-value.sync="trip.end"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-text-field
                              v-model="trip.end"
                              label="Check out"
                              append-icon="mdi-calendar"
                              color="yellow darken-1"
                              readonly
                              outlined
                              style="margin-left: 3px"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="dateEnd"
                          :min="getMinDate()"
                          no-title
                          scrollable
                          color="yellow darken-1"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="yellow darken-1"
                            @click="$refs.endMenu.isActive = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="yellow darken-1"
                            @click="
                              $refs.endMenu.save(dateEnd);
                              convertCheckOutDate();
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row justify="space-around" no-gutters>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-row>
                            <v-col>
                              <h3>Adults</h3>
                              <h5>Age 18 Or Above</h5>
                            </v-col>
                            <v-col cols="12" sm="7">
                              <v-text-field
                                v-model="adult"
                                :return-value.sync="trip.guest"
                                rounded
                                outlined
                                color="yellow darken-1"
                                track-color="grey"
                                always-dirty
                              >
                                <template v-slot:append>
                                  <v-icon
                                    app
                                    v-bind:disabled="adult < 1"
                                    :color="color"
                                    @click="decrement"
                                  >
                                    mdi-minus
                                  </v-icon>
                                  <v-icon :color="color" @click="increment">
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              <h3>Children</h3>
                              <h5>Age 2 to 17</h5>
                            </v-col>
                            <v-col cols="12" sm="7">
                              <v-text-field
                                v-model="children"
                                rounded
                                outlined
                                color="yellow darken-1"
                                track-color="grey"
                                always-dirty
                              >
                                <template v-slot:append>
                                  <v-icon
                                    v-bind:disabled="children < 1"
                                    :color="color"
                                    @click="decrement1"
                                  >
                                    mdi-minus
                                  </v-icon>
                                  <v-icon :color="color" @click="increment1">
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              <h3>Infants</h3>
                              <h5>Age Under 2</h5>
                            </v-col>
                            <v-col cols="12" sm="7">
                              <v-text-field
                                v-model="infrant"
                                color="yellow darken-1"
                                rounded
                                outlined
                                track-color="grey"
                                always-dirty
                              >
                                <template v-slot:append>
                                  <v-icon
                                    v-bind:disabled="infrant < 1"
                                    :color="color"
                                    @click="decrement2"
                                  >
                                    mdi-minus
                                  </v-icon>
                                  <v-icon :color="color" @click="increment2">
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="packageDetails.bed_details[0].single_bed"
                          >
                            <v-col>
                              <h3>Single bed</h3>
                            </v-col>
                            <v-col cols="12" sm="7">
                              <v-text-field
                                v-model="singlebedqty"
                                color="yellow darken-1"
                                rounded
                                outlined
                                track-color="grey"
                                always-dirty
                              >
                                <template v-slot:append>
                                  <v-icon
                                    v-bind:disabled="singlebedqty < 1"
                                    :color="color"
                                    @click="singlebeddecrement"
                                  >
                                    mdi-minus
                                  </v-icon>
                                  <v-icon
                                    :color="color"
                                    @click="singlebedincrement"
                                  >
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="packageDetails.bed_details[0].double_bed"
                          >
                            <v-col>
                              <h3>Double bed</h3>
                            </v-col>
                            <v-col cols="12" sm="7">
                              <v-text-field
                                v-model="doublebedqty"
                                color="yellow darken-1"
                                rounded
                                outlined
                                track-color="grey"
                                always-dirty
                              >
                                <template v-slot:append>
                                  <v-icon
                                    v-bind:disabled="doublebedqty < 1"
                                    :color="color"
                                    @click="doublebeddecrement"
                                  >
                                    mdi-minus
                                  </v-icon>
                                  <v-icon
                                    :color="color"
                                    @click="doublebedincrement"
                                  >
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="packageDetails.bed_details[0].triple_bed"
                          >
                            <v-col>
                              <h3>Triple bed</h3>
                            </v-col>
                            <v-col cols="12" sm="7">
                              <v-text-field
                                v-model="triplebedqty"
                                color="yellow darken-1"
                                rounded
                                outlined
                                track-color="grey"
                                always-dirty
                              >
                                <template v-slot:append>
                                  <v-icon
                                    v-bind:disabled="triplebedqty < 1"
                                    :color="color"
                                    @click="triplebeddecrement"
                                  >
                                    mdi-minus
                                  </v-icon>
                                  <v-icon
                                    :color="color"
                                    @click="triplebedincrement"
                                  >
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row v-if="packageDetails.bed_details[0].dormitory">
                            <v-col>
                              <h3>Dormitory</h3>
                            </v-col>
                            <v-col cols="12" sm="7">
                              <v-text-field
                                v-model="dormitorybedqty"
                                color="yellow darken-1"
                                rounded
                                outlined
                                track-color="grey"
                                always-dirty
                              >
                                <template v-slot:append>
                                  <v-icon
                                    v-bind:disabled="dormitorybedqty < 1"
                                    :color="color"
                                    @click="dormitorybeddecrement"
                                  >
                                    mdi-minus
                                  </v-icon>
                                  <v-icon
                                    :color="color"
                                    @click="dormitorybedincrement"
                                  >
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-row>
                  <v-btn
                    class="cloader"
                    :loading="loading2"
                    :disabled="loading2"
                    @click="reserve()"
                  >
                    Reserve
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <h3 class="space10" style="font-size: 20px">
              Booking Instructions:
            </h3>

            <v-row class="space11" style="margin-bottom: 10px">
              <v-col cols="12" xs="12" md="12" sm="12" lg="12">
                <h4 style="margin-bottom: 15px">Tour packages or tours only</h4>
                <div style="margin: 5px">
                  <span>
                    <v-icon color="primary"> mdi-information-outline</v-icon>
                    Submit “REQUEST TO PACKAGE QUOTATION” with your information
                    to receive the up-to-date total price and availability by
                    email. After that you can purchase the package through
                    PayPal using the email link.
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <br />
            <h3 class="space10" style="font-size: 20px">Things To Know</h3>
            <v-row class="space11" style="margin-bottom: 10px">
              <v-col cols="12" xs="12" md="4" sm="4" lg="4">
                <h4 style="margin-bottom: 15px">House Rules</h4>
                <div style="margin: 5px">
                  <span>
                    <v-icon color="primary">mdi-clock-outline</v-icon>
                    Check-in after 3:00 pm
                  </span>
                </div>
                <div style="margin: 5px">
                  <span>
                    <v-icon color="primary"> mdi-clock-outline </v-icon>
                    Check-out before 12:00 pm
                  </span>
                </div>
              </v-col>
              <v-col cols="12" xs="12" md="4" sm="4" lg="4">
                <h4 style="margin-bottom: 15px">Health & Safety</h4>
                <div style="margin: 5px">
                  <span
                    ><v-icon color="primary" style="margin-right: 5px"
                      >mdi-account-circle</v-icon
                    >
                    Committed to TuroBuddy's enhanced cleaning process</span
                  >
                </div>
              </v-col>
              <v-col cols="12" xs="12" md="4" sm="4" lg="4">
                <h4 style="margin-bottom: 15px">Cancellation Policy</h4>
                <div style="margin: 5px">
                  <span
                    ><v-icon color="primary" style="margin-right: 5px"
                      >mdi-close-circle-outline</v-icon
                    >
                    Please check the tour provider's cancellation policy in the
                    package description. Tour provider reserves the right to
                    adjust/cancel any tour for unforeseen circumstances.
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- Scroll To Top -->
        <div>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="yellow darken-1"
            @click="toTop"
          >
            <v-icon>keyboard_arrow_up</v-icon>
          </v-btn>
        </div>
        <tb-footer></tb-footer>
      </div>
    </div>
    <!-- Loader -->
    <div v-if="registrationFlag" class="loader-center">
      <div class="atom-spinner">
        <div class="spinner-inner">
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-circle">
            <img src="../assets/img/logo-01.png" class="logostyle" alt="..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const countries = require('../assets/City.json');
import StayAndExperience from '../components/ImageEL.vue';
import moment from 'moment';
import Footer from '../components/FooterEl.vue';
import { mapState } from 'vuex';
import msgHelper from '../../utils/msg-helper';
const _ = require('lodash');

export default {
  components: {
    'tb-footer': Footer,
    'tb-package-images': StayAndExperience,
  },
  data() {
    return {
      accomodationImages: [],
      carImages: [],
      singlebedprice: 0,
      doublebedprice: 0,
      triplebedprice: 0,
      dormitorybedprice: 0,
      singlebedcheckbox: false,
      name: '',
      user_name: '',
      countries: [],
      cityselect: '',
      recentMonth: new Date().toLocaleString('default', { month: 'long' }),
      checkdate: '',
      checkoutdate: '',
      dates: [this.checkdate, this.checkoutdate],

      e: '',
      error: '',
      responsecode: '',
      statuscode: '',
      message: '',
      countryresp: [],
      // Property type
      house: '',
      guesthouse: '',
      bunglow: '',
      chalet: '',
      guestsuite: '',
      cottage: '',
      hotel: '',
      resort: '',
      townhouse: '',
      flat: '',
      boutiquehotel: '',
      cabin: '',
      hostel: '',
      loft: '',
      villa: '',
      // Property type
      // Facilities
      freeparking: '',
      pool: '',
      gym: '',
      hottub: '',

      kitchen: '',
      aircondition: '',
      privatewashroom: '',
      balcony: '',
      dryer: '',
      iron: '',
      cot: '',
      heating: '',
      washingmachine: '',
      wifi: '',
      indoorfireplace: '',
      hairdryer: '',
      tv: '',
      highchair: '',
      waterfront: '',
      beachfront: '',

      // Amenities
      overlay: false,
      details: {},
      fab: false,
      registrationFlag: false,

      list: undefined,
      package_id: '',

      readMore: false,
      readMore1: false,
      dialog51: false,
      dialog52: false,
      offersDialog: false,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      picker2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      loader: null,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      username1: '',
      item: [],

      adult: 0,
      children: 0,
      infrant: 0,
      singlebedqty: 0,
      doublebedqty: 0,
      triplebedqty: 0,
      dormitorybedqty: 0,
      interval: null,
      isPlaying: false,
      dialog: false,

      date: null,
      dateStart: '',
      dateEnd: '',
      trip: {
        name: '',
        location: null,
        start: null,
        end: null,
      },
      search: '',

      packageDetails: null,
      isShowMoreImages: false,
      color: '',
      checkInDate: null,
      checkOutDate: null,
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      token: (state) => state.token,
      packageId: (state) => state.packageId,
    }),
  },
  created() {
    this.countries = countries;
    const currentRoute = this.$router.currentRoute;
    if (currentRoute && currentRoute.params) {
      const packageId = currentRoute.params.packageId;
      this.getPackageById(packageId);
    }
  },
  methods: {
    getMinDate() {
      if (this.dateStart !== '') {
        const dateConversion = moment(this.dateStart, 'YYYY-MM-DD');
        // this.dateEnd = dateConversion.format('YYYY-MM-DD');
        // console.log(this.dateEnd);
        return dateConversion.format('YYYY-MM-DD');
      }
    },
    reserve() {
      this.loader = 'loading2';
      console.log('Date start and end', this.dateStart, this.dateEnd);
      if (this.dateStart === '' || this.dateEnd === '') {
        msgHelper.showToast('warning', 'Please select check in & out date');
      } else if (this.adult === 0) {
        msgHelper.showToast(
          'warning',
          'Please select a guest before proceeding'
        );
      } else {
        const packageItem = this.packageDetails;
        localStorage.setItem('startDate', this.checkInDate);
        localStorage.setItem('endDate', this.checkOutDate);
        localStorage.setItem('adult', this.adult);
        localStorage.setItem('children', this.children);
        localStorage.setItem('infrant', this.infrant);
        localStorage.setItem('Singlebed', this.singlebedqty);
        localStorage.setItem('Doublebed', this.doublebedqty);
        localStorage.setItem('Triplebed', this.triplebedqty);
        localStorage.setItem('Dormitory', this.dormitorybedqty);

        (this.singlebedprice = 0),
          (this.doublebedprice = 0),
          (this.triplebedprice = 0),
          (this.dormitorybedprice = 0);
        if (this.singlebedqty !== 0) {
          this.singlebedprice = packageItem.single_bed_price;
          localStorage.setItem('singlebedprice', this.singlebedprice);
        } else if (this.doublebedqty !== 0) {
          this.doublebedprice = packageItem.double_bed_price;
          localStorage.setItem('doublebedprice', this.doublebedprice);
        } else if (this.triplebedqty !== 0) {
          this.triplebedprice = packageItem.triple_bed_price;
          localStorage.setItem('triplebedprice', this.triplebedprice);
        } else if (this.dormitorybedqty !== 0) {
          this.dormitorybedprice = packageItem.dormitory_price;
          localStorage.setItem('dormitorybedprice', this.dormitorybedprice);
        }
        const packageprice = packageItem.package_price;
        localStorage.setItem('packageprice', packageprice);
        this.$router.push({ name: 'Payment' });
      }
    },
    getAvailabilityDates(dates) {
      const formattedDates = _.map(dates, (date) =>
        this.$moment(date).format('Do MMMM, YYYY')
      );
      return _.join(formattedDates, ' - ');
    },
    countrySearching(packages) {
      this.countryresp = packages;
    },
    convertDate(unixDate) {
      return moment.unix(unixDate).format('DD/MM/YYYY');
    },
    convertCheckInDate() {
      const packageCheckInDate = moment(this.dateStart).unix();
      this.checkInDate = packageCheckInDate;
      return packageCheckInDate;
    },
    convertCheckOutDate() {
      const packageCheckOutDate = moment(this.dateEnd).unix();
      this.checkOutDate = packageCheckOutDate;
      return packageCheckOutDate;
    },
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    async getPackageById(id) {
      this.registrationFlag = true;
      try {
        const res = await this.$http.post('fetchParticularPackage', {
          package_id: id,
        });
        this.$store.commit('setPackageDetails', res.data.data);
        this.$store.commit('setPackageId', id);
        this.packageDetails = res.data.data; // Final Package assignment
        this.accomodationImages =
          res.data.data.accommodation[0].accommodation_asset;
        this.carImages = res.data.data.car_details[0].car_asset;

        let checkin = this.packageDetails.check_in_date;
        this.checkdate = moment.unix(checkin).format('YYYY-MM-DD');
        let checkout = this.packageDetails.check_out_date;
        this.checkoutdate = moment.unix(checkout).format('YYYY-MM-DD');
        this.dates = [this.checkdate, this.checkoutdate];
      } catch (e) {
        console.log(e);
        msgHelper.showToast(
          'error',
          'Something went wrong getting the package details'
        );
      }
      this.registrationFlag = false;
    },
    scrollToTop() {
      let currentScroll = document.documentElement.scrollTop,
        int = setInterval(frame, 6);

      function frame() {
        if (0 > currentScroll) clearInterval(int);
        else {
          currentScroll = currentScroll - 12;
          document.documentElement.scrollTop = currentScroll;
        }
      }
    },
    decrement() {
      this.adult--;
    },
    increment() {
      this.adult++;
    },
    decrement1() {
      this.children--;
    },
    increment1() {
      this.children++;
    },
    decrement2() {
      this.infrant--;
    },
    increment2() {
      this.infrant++;
    },
    singlebedincrement() {
      this.singlebedqty++;
    },
    singlebeddecrement() {
      this.singlebedqty--;
    },
    doublebedincrement() {
      this.doublebedqty++;
    },
    doublebeddecrement() {
      this.doublebedqty--;
    },
    triplebedincrement() {
      this.triplebedqty++;
    },
    triplebeddecrement() {
      this.triplebedqty--;
    },
    dormitorybedincrement() {
      this.dormitorybedqty++;
    },
    dormitorybeddecrement() {
      this.dormitorybedqty--;
    },
  },
};
</script>

<style scoped src="../assets/css/style.css"></style>
